import { Dialog, Transition } from '@headlessui/react';
import CryptoJS from 'crypto-js';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import React from 'react';
import Countdown from 'react-countdown';
import Lottie from 'react-lottie';

import { useAppDispatch, useAppSelector } from '@/redux/hook';
import { saveUser } from '@/redux/slices/userSlice';
import { toaster } from '@/utils/CuratoToast';
import { getAPIURL } from '@/utils/getAPIURL';
import { validateEmail } from '@/utils/validateEmailAddress';

import { ModalState } from '../Auth/Welcome';
import Loader from '../Loader';
import MailAnimation from '../../config/Mail.json';

export type ModalProps = {
    isOpen: boolean;
    closeModal: () => void;
};
export const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: MailAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

export default function EnterCodeModal() {
    const { isOpen, closeModal } = React.useContext<ModalProps>(ModalState);
    const { code, email } = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const router = useRouter();
    const [error, setError] = useState(false);
    const [userCode, setUserCode] = useState<string>('');
    const [resendCodeTimer, setResendCodeTime] = useState(false);
    const redirectUser = async () => {
        try {
            setLoading(true);
            if (!process.env.NEXT_PUBLIC_CODE_SECRET) {
                throw new Error('Code secret not defined');
            }
            const decryptedCode = CryptoJS.AES.decrypt(
                code,
                process.env.NEXT_PUBLIC_CODE_SECRET
            ).toString(CryptoJS.enc.Utf8);
            if (decryptedCode === userCode) {
                const addUser = await fetch(`/api/auth/login?email=${email}`);
                if (addUser.redirected && addUser.status === 200) {
                    toaster.success('Successfully logged in');
                    setLoading(false);
                    closeModal();
                    router.push(addUser.url);
                } else {
                    setLoading(false);
                    toaster.error('Server error');
                }
            } else {
                setLoading(false);
                toaster.error('Invalid code');
                return;
            }
        } catch (error) {
            setLoading(false);
            toaster.error('Invalid code');
            // eslint-disable-next-line no-console
        }
    };

    const resendCode = async () => {
        try {
            if (!validateEmail(email)) {
                toaster.error('No email address provided');
                return;
            }
            toaster.loading(`Sending an email to ${email}`);
            if (!process.env.NEXT_PUBLIC_CODE_SECRET) {
                throw new Error('Please define Code secret');
            }
            const generatedCode = Math.floor(
                1000 + Math.random() * 9000
            ).toString();

            const code = CryptoJS.AES.encrypt(
                generatedCode,
                process.env.NEXT_PUBLIC_CODE_SECRET
            ).toString();

            if (!code) {
                toaster.error('Please try again in a while');
                return;
            }
            dispatch(saveUser({ code: code, email: email, username: '' }));
            const response = await fetch(`${getAPIURL()}/api/auth/sendCode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    code: code,
                }),
            });
            const data = await response.json();
            if (data.status) {
                toaster.remove();
                toaster.success(`Email sent to ${email}`);
                setResendCodeTime(!resendCodeTimer);
            } else {
                toaster.error(data.message);
            }
        } catch (error: unknown) {
            // eslint-disable-next-line no-console
            toaster.error('Please try again ');
        }
    };

    const renderer = ({ seconds }: { seconds: number }) => {
        return <span className='font-normal'>Resend code in {seconds}s</span>;
    };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as='div'
                    className='overflow-y-auto fixed inset-0 z-10'
                    onClose={() => {
                        null;
                    }}
                >
                    <div className='px-4 min-h-screen text-center'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <Dialog.Overlay
                                as='div'
                                className='fixed inset-0 bg-black opacity-60'
                            />
                        </Transition.Child>

                        <span
                            className='inline-block h-screen align-middle'
                            aria-hidden='true'
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 scale-95'
                            enterTo='opacity-100 scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 scale-100'
                            leaveTo='opacity-0 scale-95'
                        >
                            <div className='bg-[#0F0F13] w-[450px] inline-block overflow-hidden p-6 text-left align-middle rounded-2xl shadow-xl transition-all transform'>
                                <Lottie
                                    options={defaultOptions}
                                    width={200}
                                    height={200}
                                />
                                <div className='py-4'>
                                    <h1 className=''>Enter Code</h1>
                                </div>
                                <p>
                                    Please enter the code that has been sent
                                    to&nbsp;
                                    {email}
                                </p>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        redirectUser();
                                    }}
                                >
                                    <div className='mt-4'>
                                        {error && (
                                            <div className='text-[12px] text-red-600'>
                                                Invalid code
                                            </div>
                                        )}

                                        <input
                                            type='number'
                                            placeholder='Paste/Enter code'
                                            value={userCode}
                                            onChange={(e) => {
                                                if (error) {
                                                    setError(false);
                                                }
                                                setUserCode(e.target.value);
                                            }}
                                            className='bg-secondary w-100 flex justify-center items-center px-3 py-3 mt-2 font-medium rounded-lg'
                                        />
                                    </div>
                                    {resendCodeTimer ? (
                                        <div
                                            onClick={() => resendCode()}
                                            className='flex-end my-2 text-xs text-right cursor-pointer hover:underline'
                                        >
                                            Resend code
                                        </div>
                                    ) : (
                                        <div className='flex-end my-2 text-xs text-right'>
                                            <Countdown
                                                date={Date.now() + 10000}
                                                zeroPadTime={0}
                                                renderer={({ seconds }) => {
                                                    return renderer({
                                                        seconds,
                                                    });
                                                }}
                                                onComplete={() => {
                                                    setResendCodeTime(
                                                        !resendCodeTimer
                                                    );
                                                }}
                                                zeroPadDays={0}
                                            />
                                        </div>
                                    )}

                                    <div className='relative'>
                                        {loading ? (
                                            <button
                                                title='Continue'
                                                type='button'
                                                className='py-[17px] w-100 flex justify-center items-center px-7 mb-4 font-medium text-black bg-white rounded-lg'
                                            >
                                                <Loader />
                                            </button>
                                        ) : (
                                            <button
                                                type='submit'
                                                className='w-100 flex justify-center items-center px-7 py-4 mb-4 font-medium text-black bg-white rounded-lg'
                                            >
                                                Proceed
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
