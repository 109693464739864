/* eslint-disable no-debugger */
import CryptoJS from 'crypto-js';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Image from 'next/image';
import Router from 'next/router';
import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';

import { useAppDispatch } from '@/redux/hook';
import { saveUser } from '@/redux/slices/userSlice';
import { toaster } from '@/utils/CuratoToast';
import { auth } from '@/utils/firebase';
import { getAPIURL } from '@/utils/getAPIURL';
import { validateEmail } from '@/utils/validateEmailAddress';

import Loader from '../Loader';
import EnterCodeModal, { ModalProps } from '../Modals/EnterCodeModal';
import Google from '../../assets/auth/Vector.png';

export const ModalState = React.createContext<ModalProps>({
    isOpen: false,
    closeModal: () => {
        return null;
    },
});

const Welcome = () => {
    const dispatch = useAppDispatch();
    const [Open, setIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [email, setEmail] = useState<string>('');
    const closeModal = () => {
        setIsOpen(!Open);
    };

    const Login = async () => {
        try {
            setLoading(true);
            if (!validateEmail(email)) {
                setLoading(false);
                setError(true);
                return;
            }
            // eslint-disable-next-line no-debugger
            if (!process.env.NEXT_PUBLIC_CODE_SECRET) {
                setLoading(false);
                throw new Error('Please define Code secret');
            }
            const generatedCode = Math.floor(
                1000 + Math.random() * 9000
            ).toString();

            const code = CryptoJS.AES.encrypt(
                generatedCode,
                process.env.NEXT_PUBLIC_CODE_SECRET
            ).toString();
            if (!code) {
                toaster.error('Please try again in a while');
                return;
            }
            dispatch(saveUser({ code: code, email: email, username: '' }));
            const response = await fetch(`${getAPIURL()}/api/auth/sendCode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    code: code,
                }),
            });
            const data = await response.json();
            if (data.status) {
                setLoading(false);
                closeModal();
            } else {
                setLoading(false);
                toaster.error(data.message);
            }
        } catch (error: unknown) {
            toaster.error('Please try again ');
            setLoading(false);
        }
    };

    const googleSignin = async () => {
        try {
            toaster.loading('Fetching your google authentication');
            const googleProvider = new GoogleAuthProvider();
            const response = await signInWithPopup(auth, googleProvider);
            toaster.remove();
            toaster.loading('Please wait...');
            if (response) {
                const addUser = await fetch(
                    `/api/auth/login?email=${response.user.email}`
                );
                if (addUser.redirected && addUser.status === 200) {
                    Router.push(addUser.url);
                    toaster.remove();
                } else {
                    setLoading(false);
                    toaster.remove();
                    toaster.error('Request failed 500');
                }
            }
        } catch (error) {
            toaster.remove();
            toaster.error('Google authentication failed');
        }
    };

    return (
        <>
            <Toaster />
            <ModalState.Provider
                value={{ isOpen: Open, closeModal: closeModal }}
            >
                <EnterCodeModal />
                <div className='flex justify-center mt-6'>
                    <div>
                        <h1 className='font-extrabold'>Welcome to Curato</h1>
                        <p className='mt-2 font-medium'>
                            Create a new account or log in to an existing
                            account
                        </p>
                        <button
                            type='button'
                            onClick={() => googleSignin()}
                            className='bg-secondary w-100 flex justify-center items-center px-7 py-4 mt-6 font-medium rounded-lg'
                        >
                            <Image src={Google} alt='google' />
                            &nbsp; Continue with Google
                        </button>
                        <div className='w-100 flex justify-between items-center my-2'>
                            <div className='text-textsecond font-semibold'>
                                Or
                            </div>
                            <div className='border-dashed-custom ml-2 w-full'></div>
                        </div>

                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                Login();
                            }}
                        >
                            <input
                                type='email'
                                placeholder='Enter your email address'
                                value={email}
                                onChange={(e) => {
                                    if (error) {
                                        setError(false);
                                    }
                                    setEmail(e.target.value);
                                }}
                                className='bg-secondary w-100 flex justify-center items-center px-7 py-4 mt-6 mb-3 font-medium rounded-lg'
                            />
                            {error && (
                                <div className='text-[12px] text-red-500'>
                                    Invalid email address
                                </div>
                            )}
                            {loading ? (
                                <button
                                    title='Continue'
                                    type='button'
                                    className='py-[17px] w-100 flex justify-center items-center px-7 mt-6 mb-4 font-medium text-black bg-white rounded-lg'
                                >
                                    <Loader />
                                </button>
                            ) : (
                                <button
                                    type='submit'
                                    className='w-100 flex justify-center items-center px-7 py-4 mt-6 mb-4 font-medium text-black bg-white rounded-lg'
                                >
                                    Continue
                                </button>
                            )}
                        </form>
                        {/* <p className='text-[#A9A9B7] text-xs'>
                            Signing up for a Curato account means you agree to
                            the{' '}
                            <span className='text-white underline cursor-pointer'>
                                Privacy Policy
                            </span>
                            <br />
                            and{' '}
                            <span className='text-white underline cursor-pointer'>
                                Terms of Service.
                            </span>
                        </p> */}
                    </div>
                </div>
            </ModalState.Provider>
        </>
    );
};

export default Welcome;
