import React from 'react';
import Lottie from 'react-lottie';

import LoaderAnimation from '../config/loading.json';

const defaultOptionsLoader = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const Loader = () => {
    return (
        <div>
            <Lottie options={defaultOptionsLoader} width={200} height={22} />
        </div>
    );
};

export default Loader;
