import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import CuratoLogo from '../assets/Logo.svg';

type LogoProps = {
    className?: string;
};

const Logo = ({ className }: LogoProps) => {
    return (
        <Link href='/' passHref>
            <a>
                <Image
                    src={CuratoLogo}
                    className={className}
                    alt='Curato_Logo'
                />
            </a>
        </Link>
    );
};
export default Logo;
