import toast from 'react-hot-toast';

const style = {
    background: '#1E1E24',
    color: '#fff',
};
class CustomToast {
    success = (message: string) =>
        toast.success(message, {
            style: style,
        });
    error = (message: string) =>
        toast.error(message, {
            style: style,
        });
    loading = (message: string) =>
        toast.loading(message, {
            style: style,
        });
    remove = () => toast.remove();
}

export const toaster = new CustomToast();
