import React from 'react';

import Welcome from '@/components/Auth/Welcome';
import CrispIntegration from '@/components/CrispIntegration';
import Logo from '@/components/Logo';
import Seo from '@/components/Seo';

const login = () => {
    return (
        <>
            <CrispIntegration />
            <Seo title='Curato | Login' />
            <div className='flex justify-center py-6 w-full'>
                <Logo className='cursor-pointer' />
            </div>
            <Welcome />
        </>
    );
};

export default login;
