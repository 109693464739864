import Script from 'next/script';
import React from 'react';

const CrispIntegration = () => {
    return (
        <Script
            id='crisp-init'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
                __html: `
    window.$crisp=[];window.CRISP_WEBSITE_ID="8b283a29-721f-43b2-a54e-463d59e6aeaa";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
`,
            }}
        />
    );
};

export default CrispIntegration;
